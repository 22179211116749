import { Box, Button, Card, CardMedia, Divider, Grid, List, ListItem, ListItemText, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { useContext, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MobileContext } from '../contexts/MobileContext';

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Officers from './Officers';
import FAQs from './FAQs';

const LeftArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ left: '10px', zIndex: 1000, position: 'absolute', height: '150px',}}>
            <img src="/images/left_arrow1.png" alt="Left Arrow" style={{  width: '50px', height: '50px',  opacity: 0.6, transition: 'opacity 0.3s ease', }}
                onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
                onMouseLeave={(e) => e.currentTarget.style.opacity = 0.6}
            />
        </div>
    );
};

const RightArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick} style={{ right: '70px', zIndex: 1000, position: 'absolute', height: '150px'}}>
            <img src="/images/right_arrow.png" alt="Right Arrow" style={{ width: '50px', height: '50px', opacity: 0.6, transition: 'opacity 0.3s ease'}}
                onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
                onMouseLeave={(e) => e.currentTarget.style.opacity = 0.6}
            />
        </div>
    );
};

// sorts out how many committees there are
const groupedCommittees = Officers.reduce((acc, officer) => {
    if (!acc[officer.commitee]) {
        acc[officer.commitee] = [];
    }

    acc[officer.commitee].push(officer);
    return acc;
}, {});

// labels committees based on committee value passed in
const getCommitteeName = (id) => {
    switch (parseInt(id, 10)) {
        case 1:
            return 'advisor';
        case 2:
            return 'executive committee';
        case 3:
            return 'marketing committee';
        case 4:
            return 'internal committee';
        case 5:
            return 'outreach committee';
        case 6:
            return 'automation committee';
        default:
            return 'unknown committee';
    }
};


function AboutPage ()
{
    document.title = "About | AOTW";

    const { mobile } = useContext( MobileContext )
    const img_size = mobile ? 200 : 300
    const [ isHovered, setIsHovered ] = useState( false );

    const settings = {
        dots: false, // Show navigation dots
        infinite: true, // Enable infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 1, // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll at a time
        arrows: !mobile,
        swipe: true,
        edgeFriction: true,
        prevArrow: <LeftArrow/>,
        nextArrow: <RightArrow/>
    };

    if ( !mobile )
        return (
            <Grid container spacing={ 0 } minHeight='100vh'>
                <Grid item xs={6} className="page white-bg">
                <Box marginBottom="10%" marginLeft="10%" marginRight="10%" className="white-bg slider-container">
                    <Typography variant="h3" fontWeight="bold" marginTop="15%" marginBottom="5%" textAlign="center">
                        our officers!
                    </Typography>
                    <Slider {...settings}>
                        {Object.keys(groupedCommittees).map((committeeId, index) => (
                            <div key={index}>
                                <Typography variant="h4" fontWeight="bold" color="black" textAlign="center" marginBottom="2%">
                                    {getCommitteeName(committeeId)}
                                </Typography>
                                {groupedCommittees[committeeId].map((officer) => (
                                    <Box key={officer.id} marginLeft="10%" marginRight="10%" sx={{ borderRadius: '5px', backgroundColor: 'white', padding: '10px' }}>
                                        <CardMedia
                                            className="right-shadow"
                                            component="img"
                                            src={officer.img}
                                            alt={`${officer.role} ${officer.name}`}
                                            title={officer.name}
                                            sx={{ margin: 'auto', height: '40%', width: '40%', borderRadius: '16px' }}
                                        />
                                        <Typography marginTop="8%" variant="h5" fontWeight="bold" color="black" textAlign="center">
                                            {officer.name}
                                        </Typography>
                                        <Typography variant="h7" color="black" textAlign="center"  marginBottom="2%" sx={{ width: '100%', display: 'block' }}>
                                            {officer.role}
                                        </Typography>
                                    </Box>
                                ))}
                            </div>
                        ))}
                    </Slider>
                </Box>
            </Grid>

                <Grid item xs={ 6 } className='img-bg' paddingBottom='8%'>

                    <Box>
                        <Typography variant='h2' color='white' fontWeight='bold' textAlign='right' marginRight='10%' marginTop='10%' fon>about TAMU</Typography>
                        <Typography variant='h2' color='white' fontWeight='bold' textAlign='right' marginRight='10%' marginTop='-2%' marginBottom='5%' fon>album of the week</Typography>

                        <Box marginLeft='10%' marginRight='10%'>
                            <Typography color='white' variant='h5' textAlign='right' >{ mobile ? 'faq' : 'frequently asked questions' }</Typography>
                            <Box>
                            {FAQs.map((item) => (
                                <Accordion>
                                    <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
                                        <Typography variant='h5' textAlign='left'>{item.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {item.answer}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )

    if ( mobile )
        return (
            <Card>
                <Box className='img-bg' paddingY='10%'>
                    <Slider { ...settings }>
                        {Officers.map((officer, index) => (
                            <div key={index}>
                                <Box marginLeft='10%' marginRight='10%'
                                    sx={{
                                        borderRadius: '5px',
                                        backgroundColor: 'white',
                                        padding: '15px',
                                    }}>
                                    <Typography variant='h5' style={{ fontStyle: 'italic', fontWeight: 'bold' }} color='black' textAlign='left' marginLeft='6.5%' fon>
                                        our officers:
                                    </Typography>
                                    <CardMedia 
                                        className="left-double-shadow" 
                                        component="img" 
                                        src={officer.img} 
                                        alt={`${officer.role} ${officer.name}`} 
                                        title={officer.name} 
                                        sx={{ margin: 'auto', height: '80%', width: '80%', borderRadius: '16px'}} 
                                    />
                                    <Typography 
                                        marginTop='8%' 
                                        variant='h5' 
                                        fontWeight='bold' 
                                        color='black' 
                                        textAlign='left' 
                                    >
                                        {officer.name}
                                    </Typography>
                                    <Typography variant='h7' color='black' textAlign='left' fon>
                                        {officer.role}
                                    </Typography>
                                </Box>
                                <Box marginTop='10vw'></Box>
                            </div>
                        ))}
                    </Slider>

                    <Box marginLeft='10%' marginRight='10%'
                        sx={ {
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            padding: '15px',
                        } }>
                        <Typography color='black' variant='h' style={ { fontStyle: 'italic', fontWeight: 'bold' } } textAlign='left' >{ 'frequently asked questions:' } </Typography>
                        <Box>
                            {FAQs.map((item) => (
                                <div key={item.id}>
                                    <Box marginBottom='10px'></Box>
                                    <Accordion style={{ backgroundColor: '#843a3a', color: 'white', borderRadius: '10px' }}>
                                        <AccordionSummary>
                                            <Typography variant='h5' textAlign='left'>{item.question}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {item.answer}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Card>
        )
}

export default AboutPage