import { useNavigate } from "react-router-dom";
import { Box, Stack } from '@mui/material'
import { useContext } from "react";
import { MobileContext } from "../contexts/MobileContext";

// IF CHANGING ALSO CHANGE THE MIN HEIGHT IN APP.JS TO FIT NEW HEIGHT

function Footer() {

    const { mobile } = useContext(MobileContext)

    return (
        <Box backgroundColor='#843a3a' paddingX={mobile ? '2%' : '0.5%'} paddingRight={mobile ? '3%' : '1%'} height='55px'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' height='100%'>
                <a href="https://tamu.edu"><img src="images/logos/TAMU-logo-white.png" height="45" alt="Link to Texas A&M University"></img></a>
                
                <Stack direction='row' className='more-footer' spacing={4} alignItems='center' justifyContent='center'>
                    <a href="https://instagram.com/tamuaotw" target="_blank" rel="noopener noreferrer">
                        <div class="instagram-footer">
                            <img src="images/logos/instagram-logo-white.png" class="instagram-white" alt="Link to Album of the Week Instagram"></img>
                            <img src="images/logos/instagram-logo.png" class="instagram-top" alt="Link to Album of the Week Instagram"></img>
                        </div>
                    </a>
                    
                    <a href="https://discord.gg/vvxFMvp4yR" target="_blank" rel="noopener noreferrer">
                        <div class="discord-footer">
                            <img src="images/logos/discord-logo-white.png" class="discord-white" alt="Link to Album of the Week Discord"></img>
                            <img src="images/logos/discord-logo.png" class="discord-top" alt="Link to Album of the Week Discord"></img>
                        </div>
                    </a>
                    

                    <a href="https://open.spotify.com/user/31xumcyshbbl6bobl63n6h4bpojq?si=2a09104bb77745a1" target="_blank" rel="noopener noreferrer">
                        <div class="spotify-footer">
                            <img src="images/logos/spotify-logo-white.png" class="spotify-white" alt="Link to Album of the Week Spotify"></img>
                            <img src="images/logos/spotify-logo.png" class="spotify-top" alt="Link to Album of the Week Spotify"></img>
                        </div>
                    </a>
                </Stack>
            </Stack>
        </Box>
    )
}

export default Footer