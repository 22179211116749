import { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios'
import { Typography, Button, Stack, Grid, Box, Popper, Card, IconButton, Fab } from '@mui/material'
import {display} from '@mui/system'

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import {RemoveScrollBar} from 'react-remove-scroll-bar';


import { UserContext } from '../../contexts/UserContext'
import Loading from '../../components/Loading'
import SubmitButton from '../../components/SubmitButton'
import StatusCards from '../../components/StatusCards';
import { MobileContext } from '../../contexts/MobileContext';


export default function Voting() {
    const { user } = useContext(UserContext)
    const { mobile } = useContext(MobileContext)

    const [voteOptions, setVoteOptions] = useState({}) // stores all available voting choices
    // const [selectedVotes, setSelectedVotes] = useState({}) // stores the voting choices of the user {index: bool}
    const [messages, setMessages] = useState({}) // stores error and success messages
    const [numToSelect, setNumToSelect] = useState(null) // stores the number of albums to select (default 5)

    const [anchorEl, setAnchorEl] = useState(null) // holds the element the user is hovering over
    const [selected, select] = useState(null) // holds the hovered album
    const popupRef = useRef(null) // stores a reference to the pop up created when hovering

    // ok so when you hover over an item the body scrollbar gets deleted so you only scroll on the hovered element and not the whole page ()
    // to make the left part not jitter every time you hover (from the scrollbar going away and the width getting larger), the left side is hardcoded
    // to be pixels equal to 60% of the width and the right side resizes accordingly so its not as annoying to hover


    const [currentAlbum, setCurrentAlbum] = useState(0) // store the current selected album index on the right side of the screen
    const [selectedStack, setSelectedStack] = useState([]) // stores a stack of the selected album indices for the right side (this could replace the selectedVotes dict in the future)

    const [disableSubmit, setDisableSubmit] = useState(false) // used to deactivate the submit button while submitting (prevents spam)



    useEffect(() => {
        if(user.email) getUserVotes()
        getVoting()
    }, [])

    useEffect(() => {
        if(user.email) getUserVotes()
    }, [user])

    useEffect(() => {
        console.log(selectedStack)
    }, [selectedStack])

    const validUser = user.email && user.registration_data.registered && (user.registration_data.paid || !user.registration_data.required)

    if(!mobile)
    return (
        <div>
        <Stack direction='row' height='100%' minHeight='100vh' minWidth="100vw">
            <Box className='white-bg' width="60vw" overflowY='hidden'>
                { voteOptions && voteOptions.length > 0 && 
                    <Box margin='25px' marginTop='5%' paddingBottom='10vw'>
                        <Typography variant='h2' fontWeight='bold' marginBottom='2%'>select your top {numToSelect} albums</Typography>
                        <Grid container spacing={6}>
                            {voteOptions.map((option, i) => (
                                <Grid item xs={3} marginTop='1.5vw'>
                                    <Box position='relative'>
                                        { selectedStack.includes(i) && // if selected
                                        <>
                                            { option.name.length % 3 === 0 && // uses the album length % 3 instead of random number between 0-2
                                                <Box position='absolute' width='100%' top='-88%' sx={{pointerEvents: 'none'}}>
                                                    <img src='images/little-guys/selected-1.png' width='100%'></img>
                                                </Box>
                                            }
                                            { option.name.length % 3 === 1 && // uses the album length % 3 instead of random number between 0-2
                                                <Box position='absolute' width='80%' top='-70%' marginLeft='10%' sx={{pointerEvents: 'none'}}>
                                                    <img src='images/little-guys/selected-2.png' width='100%'></img>
                                                </Box>
                                            }
                                            { option.name.length % 3 === 2 && // uses the album length % 3 instead of random number between 0-2
                                                <Box position='absolute' width='80%' top='-68%' marginLeft='10%' sx={{pointerEvents: 'none'}}>
                                                    <img src='images/little-guys/selected-3.png' width='100%'></img>
                                                </Box>
                                            }
                                        </>
                                        }
                                        <img src={option.image.url} className='right-shadow' width='100%'
                                        onMouseEnter={(e) => {
                                            select(i)
                                            setAnchorEl(e.currentTarget)
                                            document.body.classList.add('disable-scroll');
                                            
                                        }}
                                        onMouseLeave={() => {
                                            select(null)
                                            setAnchorEl(null)
                                            document.body.classList.remove('disable-scroll');
                                        }}
                                        onWheel={(e) => popupRef.current.scrollTop += e.deltaY} // when the user scrolls on the image, scrolls the popup (mouse events ignored on popup)
                                        onClick={() => {
                                            let tempArr = [...selectedStack]
                                            if(!selectedStack.includes(i)) { // if album selected
                                                tempArr.push(i)
                                                setSelectedStack(tempArr)
                                                setCurrentAlbum(tempArr.length-1)
                                            }
                                            else { // deselected
                                                tempArr = tempArr.filter((index) => index !== i)
                                                setSelectedStack(tempArr)
                                                if(currentAlbum >= tempArr.length) setCurrentAlbum(tempArr.length-1)
                                            }
                                        }}
                                        ></img>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                }

                { voteOptions[selected] && 
                    <Popper
                        anchorEl={anchorEl}
                        open={anchorEl}
                        placement='top-start'
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{width: '30%', pointerEvents: 'none', zIndex: '2'}}
                        >

                        <Box position='relative' width='100%'>
                            <Card ref={popupRef} sx={{paddingX: '2.627vw', paddingY: '2.102vw', marginX: '-2.627vw', marginY: '-2.102vw', borderRadius: '20px', backgroundColor: selectedStack.includes(selected) ? '#843a3a' : '#fffef2', color: selectedStack.includes(selected) ? 'white' : 'black', position: 'absolute', width: '100%', maxHeight: '20vw', overflowY: 'auto'}} >
                                <Stack direction='row' spacing={3} sx={{width: '100%'}}>
                                    <Box position='relative' width='45%' paddingLeft="1.9%">
                                        <img src={voteOptions[selected].image.url} width='100%' height="83%" className='right-shadow'></img>
                                    </Box>
                                    
                                    <Stack direction='column' width='55%'>
                                        <Typography variant='h5' fontWeight='bold'>title: {
                                            voteOptions[selected].name.length > 25 ? voteOptions[selected].name.slice(0, 22)+'...' : voteOptions[selected].name
                                        }</Typography>
                                        <Typography variant='h5' fontWeight='bold'>artist: {
                                            voteOptions[selected].artist.length > 25 ? voteOptions[selected].artist.slice(0, 22)+'...' : voteOptions[selected].artist
                                        }</Typography>
                                        <Typography variant='p' fontWeight='bold'>subgenre: {
                                            voteOptions[selected].genre.length > 25 ? voteOptions[selected].genre.slice(0, 22)+'...' : voteOptions[selected].genre
                                        }</Typography>
                                        <Typography variant='p' fontWeight='bold'>released: {voteOptions[selected].releaseDate}</Typography>
                                    </Stack>
                                </Stack>

                                {voteOptions[selected].description && 
                                <>
                                    <Typography variant='p' fontWeight='bold'>album description:</Typography>
                                    <Typography sx={{wordWrap: 'break-word', fontSize: '0.946vw'}}>{voteOptions[selected].description}</Typography>
                                </>
                                }
                            </Card>
                        </Box>
                    </Popper>
                }
            </Box>

            <Stack width="40vw" className='img-bg' justifyContent='flex-start' alignItems='center' direction='column' sx={{overflowY: 'auto', padding: "0"}} >
                { validUser && 
                    <>
                    <Box sx={{marginTop: '10%', marginBottom: '0px', width: '90%'}}>
                        { selectedStack.length > 0 && 
                        <>
                            {(currentAlbum + 1) <= numToSelect && 
                                <Typography variant='h5' color='white' marginLeft='2%'>selection {currentAlbum+1} of {numToSelect}:</Typography>
                            }
                            {(currentAlbum + 1) > numToSelect && 
                                <Typography variant='h5' color='#843a3a' marginLeft='2%' sx={{
                                    textShadow: "red -2px 2px"
                                }}>selection {currentAlbum+1} of {numToSelect}:</Typography>
                            }
                            <Card sx={{paddingX: '50px', paddingTop: '40px', paddingBottom: '20px', borderRadius: '20px', backgroundColor: '#fffef2', overflowY: 'auto' }} >
                            <Stack direction='row' spacing={3} sx={{width: '100%'}}>

                                <Box position='relative' width='40%'>
                                    <img src={voteOptions[selectedStack[currentAlbum]].image.url} width='100%' className='right-shadow'></img>
                                </Box>

                                <Box width='60%'>
                                    <Typography variant='h4' fontWeight='bold'>title: {
                                        voteOptions[selectedStack[currentAlbum]].name.length > 25 ? voteOptions[selectedStack[currentAlbum]].name.slice(0, 22)+'...' : voteOptions[selectedStack[currentAlbum]].name
                                    }</Typography>
                                    <Typography variant='h5' fontWeight='bold'>artist: {
                                        voteOptions[selectedStack[currentAlbum]].artist.length > 25 ? voteOptions[selectedStack[currentAlbum]].artist.slice(0, 22)+'...' : voteOptions[selectedStack[currentAlbum]].artist
                                    }</Typography>

                                    <Typography variant='h6' fontWeight='bold'>released: {voteOptions[selectedStack[currentAlbum]].releaseDate}</Typography>

                                    <Typography variant='p' fontWeight='bold'>subgenre: {
                                        voteOptions[selectedStack[currentAlbum]].genre.length > 25 ? voteOptions[selectedStack[currentAlbum]].genre.slice(0, 22)+'...' : voteOptions[selectedStack[currentAlbum]].genre
                                    }</Typography>

                                </Box>

                            </Stack>

                            <Stack direction='column'>
                                {voteOptions[selectedStack[currentAlbum]].sample && 
                                    <Box className='center' height="90px" marginTop='0.525vw'>
                                        <iframe 
                                            src={`https://open.spotify.com/embed/track/${voteOptions[selectedStack[currentAlbum]].sample}?utm_source=generator`}  
                                            height="100" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                            style={{ top: 0, left: 0, width: '100%', border: 0, margin: 0}}>
                                        </iframe>
                                    </Box>
                                }

                                {voteOptions[selectedStack[currentAlbum]].description && 
                                <>
                                    <Typography variant='p' fontWeight='bold'>album description:</Typography>
                                    <Typography>{voteOptions[selectedStack[currentAlbum]].description}</Typography>
                                </>
                                }
                                
                            </Stack>
                            <Box display='flex' flexDirection='row-reverse' paddingTop='1vw' marginRight='-20px'>
                                <Button variant='contained' 
                                    onClick={() => { 
                                        let tempArr = [...selectedStack]
                                        tempArr.splice(currentAlbum, 1)
                                        
                                        setSelectedStack(tempArr)
                                        if(currentAlbum >= tempArr.length) setCurrentAlbum(tempArr.length-1)
                                    }}>
                                    <Typography variant='h5'>deselect</Typography>
                                </Button>
                            </Box>
                            </Card>
                        </>
                        }
                    </Box>

                    { selectedStack.length > 0 && 
                    <>
                        <Box display='flex' justifyContent='space-between' width='70%' sx={{marginY: '3%'}}>
                            <IconButton onClick={() => currentAlbum-1 >= 0 ? setCurrentAlbum(currentAlbum-1) : ''}>
                                <KeyboardArrowLeftRoundedIcon fontSize='large' sx={{color: 'white'}} />
                            </IconButton>

                            <SubmitButton text='submit' action={() => submitVotes()} completed={messages.success} disabled={disableSubmit || selectedStack.length !== numToSelect} />

                            <IconButton onClick={() => currentAlbum+1 < selectedStack.length ? setCurrentAlbum(currentAlbum+1) : ''}>
                                <KeyboardArrowLeftRoundedIcon fontSize='large' sx={{color: 'white', rotate: '180deg'}} />
                            </IconButton>
                        </Box>
                    </>
                    }
                </>
                }

                { !validUser &&
                    <StatusCards verb='voting' />
                }
            </Stack>
        </Stack>
        </div>
    )


    if(mobile)
    return (
    <>
        { !validUser && 
            <Box className='img-bg' minHeight='100vh' margin='0' padding='0'>
                <Box paddingTop='10%'>
                    <StatusCards verb='voting' />
                </Box>
            </Box>
        }


        { validUser && 
        <Box position='relative' className='img-bg' minHeight='100vh' margin='0' padding='0' >
            <Box paddingY='10%'>
                <Box className='red-bg' color='white' textAlign='center' marginX='10%' paddingY='2%' borderRadius='10px'>
                    <Typography variant='h3'>vote</Typography>
                    <Typography variant='h2' fontWeight='bold'>{selectedStack.length}/{numToSelect}</Typography>
                    <Typography variant='h5'>albums selected</Typography>
                </Box>
            </Box>
            { voteOptions && voteOptions.length > 0 && 
                <Stack paddingBottom='5%'>
                    {voteOptions.map((option, i) => (
                        <Box className={selectedStack.includes(i) ? 'blue-bg' : 'tan-bg'} color={selectedStack.includes(i) ? 'white' : 'black'} padding='2%' borderRadius='10px' margin='5%' onClick={(e) => {
                            let tempArr = [...selectedStack]
                            if(!selectedStack.includes(i)) { // if album selected
                                tempArr.push(i)
                                setSelectedStack(tempArr)
                            }
                            else { // deselected
                                tempArr = tempArr.filter((index) => index !== i)
                                setSelectedStack(tempArr)
                            }
                        }}>
                            <Stack direction='row' alignItems='center' marginRight="5%">
                                <img src={option.image.url} width='27%' height='27%' className='left-shadow-mobile'></img>
                                <Stack direction='column' marginLeft='5%' width='100%'>
                                    <Typography variant='h5' fontWeight='bold' maxWidth="59vw"  textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                                        {option.name}
                                    </Typography>
                                    <Box className='center' height="90px" marginTop='0.525vw'>
                                        <iframe 
                                            src={`https://open.spotify.com/embed/track/${option.sample}?utm_source=generator`}  
                                            height="100" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                            style={{ top: 0, left: 0, width: '100%', border: 0, margin: 0}}>
                                        </iframe>
                                    </Box>
                                    <Stack direction='row' justifyContent='space-between' alignItems='flex-start' paddingRight='5%'>
                                        <Typography>sub-genre(s): {option.genre}</Typography>
                                        <Typography>{option.releaseDate}</Typography>
                                    </Stack>
                                    <Typography>artist: {option.artist}</Typography>
                                </Stack>
                                
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            }
            <Box position='sticky' bottom='0px'  paddingBottom='5%' width='100%'>
                <Box display='flex' width='95%' justifyContent='flex-end' >
                    { selectedStack.length === numToSelect && 
                        <SubmitButton text='submit' action={() => submitVotes()} completed={messages.success} disabled={disableSubmit} />
                    }
                    { selectedStack.length !== numToSelect && 
                        <Button variant='contained'>
                            <Typography variant='h3'>{selectedStack.length}/{numToSelect}</Typography>
                        </Button>
                    }
                </Box>
            </Box>
        </Box>
        }
    </>
    )
    
    
    // gets all the voteOptions
    function getVoting() {
        const options = {
            method: 'GET',
            url: '/api/voting-options'
        }
        // get data from back end
        axios.request(options).then((res) => {
            let albums = res.data.albums
            // format image to be dictionary containing size and url
            albums.map((album) => {
                album.image = {url: album.image, width: 300, height: 300}
            })
            setVoteOptions(res.data.albums)

            // 0-4 = select 1
            // 5+ = select 3
            // 10+ = select 5
            // 30+ = select 10
            if(res.data.albums.length >= 30) setNumToSelect(10)
            else if(res.data.albums.length >= 10) setNumToSelect(5)
            else if(res.data.albums.length >= 5) setNumToSelect(3)
            else setNumToSelect(1)
        })
        .catch((err) => {
            console.log(err)
            setMessages({error: "Error getting voting options"})
        })
    }
    
    
    // signs in user and retreives previous voting data (if any)
    function getUserVotes() {
        const options = {
            method: 'GET',
            url: '/api/user-voting-info',
            params: {email: user.email}
        }
        axios.request(options).then((res) => {
            if(res.data.error) {
                setMessages({error: res.data.message})
                return
            }
            let data = res.data.votes
            console.log(data)
            if(!data) return

            console.log(voteOptions)

            let options = [...voteOptions]
            let indices = options
                .filter(option => data.findIndex(selection => option.NOMINATION_ID === selection.ALBUM) !== -1)
                .map(item => options.indexOf(item));

            setSelectedStack(indices)
        })
    }
    
    
    function submitVotes() {

        setDisableSubmit(true)
        // convert list of selected indices into selected nomination_id values
        let selected = selectedStack.map((selection) => voteOptions[selection].NOMINATION_ID)
        
        // make sure all albums are selected
        if(selected.length !== numToSelect) {
            setDisableSubmit(false)
            setMessages({error: `Please select ${numToSelect} options. (selected ${selected.length})`})
            return
        }
        // make sure they are signed in
        if(user === null || user.email === undefined){ // change to only accept tamu emails
            setDisableSubmit(false)
            setMessages({error: 'Please sign in with your TAMU email'})
            return
        }
        
        // send data to back end
        const options = {
            method: 'POST',
            url: '/api/insert-votes',
            data: {email: user.email, votes: selected}
        }
        axios.request(options).then((res) => {
            if(res.data.error) {
                setDisableSubmit(false)
                setMessages({error: res.data.message})
                return
            }
            setMessages({success: res.data.message})
            setDisableSubmit(false)
        })
        .catch((err) => {
            console.log(err)
            setMessages({error: 'Error submitting votes'})
            setDisableSubmit(false)
        })
    }
}