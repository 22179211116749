// Officers for the About page

const Officers = [
  //Advisor
  {
    id: 1,
    name: "Dr. Matthew Campbell",
    role: "Advisor",
    img: "/images/aotw-officer-photos/campbell-head-crop.png",
    commitee: 1
  },

  // EXECUTIVE COMMITTEE

  // President
  {
    id: 2,
    name: "Casey Ear",
    role: "AOTW President",
    img: "/images/aotw-officer-photos/casey-head-crop.png",
    commitee: 2
  },

  // Vice President
  {
    id: 3,
    name: "Jessie Hoepfl",
    role: "AOTW Vice President",
    img: "/images/aotw-officer-photos/jessie-head-crop.png",
    commitee: 2
  },

  // Treasurer
  {
    id: 4,
    name: "Abhi Muppidi",
    role: "AOTW Treasurer",
    img: "/images/aotw-officer-photos/abhi-head-crop.png",
    commitee: 2
  },

  // MARKETING COMMITTEE

  // Chair
  {
    id: 5,
    name: "Alanna Broughton",
    role: "Marketing Committee Chair",
    img: "/images/aotw-officer-photos/alanna-head-crop.png",
    commitee: 3
  },

  // Officers
  {
    id: 6,
    name: "Audrey Schroeder",
    role: "Marketing Committee Officer",
    img: "/images/aotw-officer-photos/audrey-head-crop.png",
    commitee: 3
  },

  {
    id: 7,
    name: "Evie Terrell",
    role: "Marketing Committee Officer & Platform Manager",
    img: "/images/aotw-officer-photos/evie-head-crop.png",
    commitee: 3
  },

  // INTERNAL COMMITTEE

  // Chair
  {
    id: 8,
    name: "Maitri Shekar",
    role: "Internal Committee Chair",
    img: "/images/aotw-officer-photos/maitri-head-crop.png",
    commitee: 4
  },

  // Officers
  {
    id: 9,
    name: "Nikoli Alsman",
    role: "Internal Committee Officer",
    img: "/images/aotw-officer-photos/nikoli-head-crop.png",
    commitee: 4
  },

  {
    id: 10,
    name: "Aicha Colon",
    role: "Internal Committee Officer",
    img: "/images/aotw-officer-photos/aicha-head-crop.png",
    commitee: 4
  },

  // OUTREACH COMMITTEE

  // Chair
  {
    id: 11,
    name: "Jason Dobbins",
    role: "Outreach Committee Chair",
    img: "/images/aotw-officer-photos/jason-head-crop.png",
    commitee: 5
  },

  // Officers
  {
    id: 12,
    name: "Jesse Liu",
    role: "Outreach Committee Officer",
    img: "/images/aotw-officer-photos/jesse-head-crop.png",
    commitee: 5
  },

  {
    id: 13,
    name: "Julian Garza",
    role: "Outreach Committee Officer",
    img: "/images/aotw-officer-photos/julian-head-crop.png",
    commitee: 5
  },

  // AUTOMATION COMMITTEE

  // Chair
  {
    id: 14,
    name: "Aayush Sharma",
    role: "Automation Committee Chair",
    img: "/images/aotw-officer-photos/aayush-head-crop.png",
    commitee: 6
  },

  //Reserved for Kieran & Seb

  //   // Officers
  //   {
  //     id: 15,
  //     name: "Kieran Kahler",
  //     role: "Automation Committee Officer",
  //     img: "",
  //   
  //   },

  //   {
  //     id: 16,
  //     name: "Sebastian Silva",
  //     role: "Automation Committee Officer",
  //     img: "",
  //   
  //   },
];

export default Officers;
