import jwt_decode from 'jwt-decode'
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import { Box } from '@mui/material'

export default function SignIn({clientid, handle}) {
    return (
        <Box id="google">
            <GoogleOAuthProvider clientId={clientid}> 
                <GoogleLogin
                onSuccess={credentialResponse => {
                    let decoded = jwt_decode(credentialResponse.credential)
                    handle(decoded)
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                width='300px'
                />
            </GoogleOAuthProvider>
        </Box>
    )
}