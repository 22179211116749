import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Typography, Container, TextField, Button, Stack, Select, MenuItem, Box, Card, Grid, CardMedia, ButtonBase, IconButton, FormControl, InputLabel } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import SubmitButton from '../../components/SubmitButton'

import NominationsPage from '../NominationsPage'

import { UserContext } from '../../contexts/UserContext'
import { MobileContext } from '../../contexts/MobileContext'

import StatusCards from '../../components/StatusCards';


export default function Nominations({ genre }) {
    const { user } = useContext(UserContext)

    const [messages, setMessages] = useState({}) // stores error and success messages
    /* {
      error: text,
      success: text
    } */

    const [nomination, setNomination] = useState({}) // stores the nomination that is currently entered
    /*
      {
        image: cover image {url: text}
        releaseDate: year (text)
        album: album name (text)
        artist: artist name (text)
        tracks: number of tracks (text)
        genre: genre (text)
        spotifyID: spotify id (text)
        popularity: spotify popularity (int or undefined)
        sampleSongs: all songs of album [text...]
        link: url to spotify album (optional) (text)
      }
    */
    const [page, setPage] = useState(0) // 0 = enter, 1 = select, 2 = edit, 3 = submitted
    const [searchAlbum, setSearchAlbum] = useState({}) // album entered from text fields to be searched {album: text, artist: text}
    const [options, setOptions] = useState() // voting options (returned from search)
    const [selectedIndex, setSelectedIndex] = useState(0) // index of option chosen

    const [sampleSongs, setSampleSongs] = useState() // holds the songs of the album the user can select for samples
    const [disabledSubmit, setDisabledSubmit] = useState(false) // used to disable submit when sending to server (cant spam)

    const [refresh, setRefresh] = useState(false) // refreshes the nominations page when the user adds or removes a nomination

    const { mobile } = useContext(MobileContext)

    // if user is updated, fetch nomination information (go to last page if they have nom)
    useEffect(() => {
        if(user.email) getUserNomination()
    }, [user])

    const validUser = user.email && user.registration_data.registered && (user.registration_data.paid || !user.registration_data.required)

    if(!mobile) 
      return (
      <Box className="container" height='100%' width='100%' position='absolute'>
        <Box className='left-panel scrollable-content white-bg'>
            <NominationsPage genre={genre} refresh={refresh} />
        </Box>

          <Stack className='right-panel black-bg' justifyContent='flex-start' alignItems='center' direction='column' sx={{overflowY: 'scroll'}} >
            <Box sx={{marginTop: 'auto', marginBottom: 'auto', width: '100%'}}>
              <StatusCards verb='nominating' />


              { page === 0 && validUser && // ENTER NOMINATION
              <Grid justifyContent='center' alignItems='center' display='flex'>
                <Card sx={{width: '75%', padding: '2%', borderRadius: '20px'}} className='center'>
                  <Typography variant='h4' fontWeight='bold' marginBottom='2%'>add a nomination</Typography>
                  <Stack justifyContent='center' alignItems='center' textAlign='center' direction='column' paddingX='5%' spacing={2}>

                    <TextField 
                      autoFocus fullWidth placeholder='album title'
                      value={searchAlbum.album} 
                      onChange={ (e) => {setSearchAlbum({...searchAlbum, album: e.target.value.slice(0, 100)})} }
                      inputProps={{style: {fontSize: '1.576vw'}}}
                      InputLabelProps={{style: {fontSize: '1.576vw'}}}
                    />
                    <TextField 
                      fullWidth placeholder='album artist' 
                      value={searchAlbum.artist} 
                      onChange={ (e) => {setSearchAlbum({...searchAlbum, artist: e.target.value.slice(0, 100)})} }
                      inputProps={{style: {fontSize: '1.576vw'}}}
                      InputLabelProps={{style: {fontSize: '1.576vw'}}}
                    />
                    <Typography variant='h4'>or</Typography>
                    <TextField fullWidth placeholder='spotify link'
                      value={searchAlbum.link} 
                      onChange={ (e) => {setSearchAlbum({...searchAlbum, link: e.target.value})} }
                      inputProps={{style: {fontSize: '1.576vw'}}}
                      InputLabelProps={{style: {fontSize: '1.576vw'}}}
                    />

                    <Button variant='contained' width='fit-content' fullWidth='false'
                      onClick={() => {
                        spotifySend()
                      }}>
                      <SearchIcon sx={{fontSize: '2vw', paddingRight: '25px'}} />
                      <Typography variant='h4' fontWeight='bold'>search</Typography>
                    </Button>
                    
                  </Stack>
                </Card>
              </Grid>
              }

              { page === 1 && options && options.length > 0 && validUser && // SELECT NOMINATION FROM OPTIONS
              <Stack direction='column' alignItems='center' spacing={4} >
                <Stack direction='row' justifyContent='space-around' display='flex' alignItems='center' color='white' width='100%'>

                  <IconButton onClick={() => { if(selectedIndex > 0) setSelectedIndex(selectedIndex-1) }}>
                    <KeyboardArrowLeftRoundedIcon fontSize='large' sx={{color: 'white'}} />
                  </IconButton>

                  { selectedIndex < options.length && // INDEX IS WITHIN BOUNDS
                  <Box width='50%'>
                    <Card sx={{paddingX: '50px', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                      <Grid justifyContent='center' alignItems='center' display='flex'>
                        <img src={options[selectedIndex].image.url} width='100%' className='right-shadow'></img>
                      </Grid>
                      <Box width='100%'>
                        <Typography variant='h5' textAlign='left' fontWeight='bold' paddingTop='20px'>{
                          options[selectedIndex].album.length > 25 ? options[selectedIndex].album.slice(0, 22)+'...' : options[selectedIndex].album
                        }</Typography>
                        <Typography variant='h6' textAlign='left'>{
                          options[selectedIndex].artist.length > 25 ? options[selectedIndex].artist.slice(0, 22)+'...' : options[selectedIndex].artist
                        }</Typography>
                      </Box>
                      <Box textAlign='right' marginRight='-20px'>
                        <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                      </Box>
                    </Card>
                  </Box>
                  }

                  { selectedIndex >= options.length && // WENT PAST OPTIONS (DID NOT FIND)
                  <Box width='50%'>
                    <Card sx={{paddingX: '50px', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                      <Grid justifyContent='center' alignItems='center' display='flex'>
                            <img src='images/little-guys/confused.png' width='90%'></img>
                      </Grid>
                      <Box minWidth='100%'>
                        <Typography variant='h5' textAlign='left' fontWeight='bold' paddingTop='20px'>not here?</Typography>
                        <Typography variant='h6' textAlign='left'>select to enter manually</Typography>
                      </Box>
                      <Box textAlign='right' marginRight='-20px'>
                        <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                      </Box>
                    </Card>
                  </Box>
                  }

                  <IconButton onClick={() => { if(selectedIndex < options.length) setSelectedIndex(selectedIndex+1) }}>
                    <KeyboardArrowLeftRoundedIcon fontSize='large' sx={{color: 'white', rotate: '180deg'}} />
                  </IconButton>

                </Stack>

                <Stack direction='row' spacing={10}>
                  <Button variant='contained'
                    onClick={() => {
                      setPage(0) // go back to first page
                      setSelectedIndex(0) // reset their current selection
                      setOptions() // delete options
                      setMessages({}) // reset error messages
                  }}>
                    <Typography variant='h5'>back</Typography>
                  </Button>

                  <Button variant='contained' 
                    onClick={() => {
                      if(selectedIndex >= options.length) // did not find album
                        setNomination(searchAlbum) // set the album to whatever they entered
                      else {// found album
                        setNomination(options[selectedIndex])
                        getSongs(options[selectedIndex].spotifyID)
                      }
                      setPage(2)
                    }}>
                    <Typography variant='h5'>select</Typography>
                  </Button>
                </Stack>
              </Stack>
              }


              { page === 2 && validUser && // FILL IN INFO
                <Card sx={{paddingX: '50px', paddingY: '40px', borderRadius: '20px', backgroundColor: '#fffef2', minHeight: '90%', overflowY: 'auto'}} >
                  <Stack direction='row' spacing={3} sx={{width: '100%'}}>
                    {nomination.image && // image found from spotify
                      <Box position='relative' width='40%'>
                        <img src={nomination.image.url} width='100%' className='right-shadow'></img>
                        { !nomination.spotifyID && // not originally from spotify (edit icon)
                          <IconButton 
                            onClick={() => {setNomination({...nomination, image: undefined})}}
                            sx={{right: 0, bottom: 0, position: 'absolute', background: 'black', ':hover': {background: 'gray'}}}>
                            <EditIcon sx={{color: 'white'}} ></EditIcon>
                          </IconButton>
                        }
                      </Box>
                    }
                    {!nomination.image && // no image found
                      <TextField fullWidth placeholder='image url'
                      value={nomination.image?.url} 
                      onChange={ (e) => {setNomination({...nomination, image: {url: e.target.value}})} }
                      inputProps={{style: {fontSize: '1.261vw'}}}
                      InputLabelProps={{style: {fontSize: '1.261vw'}}}
                    />
                    }

                    <Box width='60%'>
                      <Typography variant='h4' fontWeight='bold'>title: {
                        nomination.album.length > 25 ? nomination.album.slice(0, 22)+'...' : nomination.album
                      }</Typography>
                      <Typography variant='h5' fontWeight='bold'>artist: {
                        nomination.artist.length > 25 ? nomination.artist.slice(0, 22)+'...' : nomination.artist
                      }</Typography>

                      { nomination.spotifyID && // release date found (spotify API)
                        <Typography variant='h6' fontWeight='bold'>released: {nomination.releaseDate}</Typography>
                      }
                      { !nomination.spotifyID && // no release date found (not on spotify)
                        <TextField fullWidth placeholder='release year'
                          value={nomination.releaseDate} 
                          onChange={ (e) => {setNomination({...nomination, releaseDate: e.target.value})} }
                          inputProps={{style: {fontSize: '1.261vw'}}}
                          InputLabelProps={{style: {fontSize: '1.261vw'}}}
                          sx={{paddingTop: '3%'}}
                        />
                      }

                      {sampleSongs &&
                        <FormControl fullWidth>
                          <InputLabel>sample song (optional)</InputLabel>
                          <Select 
                            label='sample song (optional)' 
                            value={nomination.sample} 
                            sx={{borderRadius: '50px', maxWidth: '100%'}}
                            onChange={(e) => {setNomination({...nomination, sample: e.target.value})}}
                          >
                              { sampleSongs.map(song => (
                                <MenuItem value={song.id}>{song.name}</MenuItem>
                              )) }
                          </Select>
                        </FormControl>
                      }
                    </Box>
                  </Stack>

                  <Stack direction='column'>
                    <TextField fullWidth placeholder='sub-genres'
                        value={nomination.genre} 
                        onChange={ (e) => {setNomination({...nomination, genre: e.target.value})} }
                        inputProps={{style: {fontSize: '1.261vw'}}}
                        InputLabelProps={{style: {fontSize: '1.261vw'}}}
                        sx={{paddingTop: '3%'}}
                    />

                    <TextField fullWidth placeholder='album description (optional)' multiline
                        value={nomination.description} 
                        onChange={ (e) => {setNomination({...nomination, description: e.target.value})} }
                        inputProps={{style: {fontSize: '1.261vw'}}}
                        InputLabelProps={{style: {fontSize: '1.261vw'}}}
                        sx={{paddingTop: '3%', height: '100%'}}
                        rows={7}
                    />
                    <Typography varaint='p' color={nomination.description?.length > 1000 ? 'red' : 'black'} sx={{paddingLeft: '5px'}}>{`${nomination.description?.length || 0} of max 1000 characters`}</Typography>

                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '-30px', marginTop: '3%'}}>
                      <Button variant='contained' 
                        onClick={() => { 
                            if(options) setPage(1)
                            else setPage(0)
                            setNomination({}) // reset nomination info
                            setSampleSongs() // reset sample songs
                            setMessages({}) // reset error messages
                        }}>
                          <Typography variant='h5'>back</Typography>
                      </Button>
                      <Button variant='contained' disabled={disabledSubmit} onClick={() => { submitNomination() }}>
                          <Typography variant='h5'>submit</Typography>
                      </Button>
                    </Box>
                    
                  </Stack>
                </Card>
              }


              { page === 3 && validUser && // NOMINATION FINISHED, SHOWS USER THEIR NOMINATION
              <Stack direction='column' justifyContent='center' alignItems='center'>
                <Typography color='white' variant='h6' fontWeight='medium'>your current nomination:</Typography>
                <Box width='50%'>
                    <Card sx={{paddingX: '50px', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                      <Grid justifyContent='center' alignItems='center' display='flex'>
                            <img src={nomination.image.url} width='100%' className='right-shadow'></img>
                      </Grid>
                    <Typography variant='h5' textAlign='left' fontWeight='bold' paddingTop='20px'>{
                      nomination.album.length > 25 ? nomination.album.slice(0, 22)+'...' : nomination.album
                    }</Typography>
                    <Typography variant='h6' textAlign='left'>{
                      nomination.artist.length > 25 ? nomination.artist.slice(0, 22)+'...' : nomination.artist
                    }</Typography>
                    <Box textAlign='right' marginRight='-20px'>
                      <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                    </Box>
                  </Card>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '-30px', marginTop: '5%', width: '50%'}}>
                  <Button variant='contained' 
                    onClick={() => {
                      setMessages({})
                      setPage(2)
                      getSongs(nomination.spotifyID)
                    }}>
                    <Typography variant='h5'>edit</Typography>
                  </Button>
                  <Button variant='contained' onClick={() => deleteNomination()}>
                    <Typography variant='h5'>delete</Typography>
                  </Button>
                </Box>
              </Stack>
              }
            </Box>
            <Typography textAlign='center' color='#ff6865' variant='h5'>{messages.error}</Typography>
          </Stack>
        </Box>
    )


    if(mobile)
      return(
        <Stack height='100%' className='black-bg' paddingTop='10%' sx={{overflowY: 'scroll'}}>
          <Stack justifyContent='flex-start' alignItems='center' direction='column' height='100%' >
          <Box sx={{marginTop: 'auto', marginBottom: 'auto', width: '100%', height: '100%', paddingBottom: '10%'}}>
            { !validUser && 
              <StatusCards verb='nominating' />
            }


            { page === 0 && validUser && // ENTER NOMINATION
            <Grid justifyContent='center' alignItems='center' display='flex'>
              <Card sx={{width: '75%', padding: '2%', borderRadius: '20px'}} className='center'>
                <Typography variant='h3' fontWeight='bold' marginBottom='2%' marginTop='2%'>add a nomination</Typography>
                <Stack justifyContent='center' alignItems='center' textAlign='center' direction='column' paddingX='5%' spacing={2}>

                  <TextField 
                    autoFocus fullWidth placeholder='album title'
                    value={searchAlbum.album} 
                    onChange={ (e) => {setSearchAlbum({...searchAlbum, album: e.target.value.slice(0, 100)})} }
                    inputProps={{style: {fontSize: '5vw'}}}
                    InputLabelProps={{style: {fontSize: '5vw'}}}
                  />
                  <TextField 
                    fullWidth placeholder='album artist' 
                    value={searchAlbum.artist} 
                    onChange={ (e) => {setSearchAlbum({...searchAlbum, artist: e.target.value.slice(0, 100)})} }
                    inputProps={{style: {fontSize: '5vw'}}}
                    InputLabelProps={{style: {fontSize: '5vw'}}}
                  />
                  <Typography variant='h4'>or</Typography>
                  <TextField fullWidth placeholder='spotify link'
                    value={searchAlbum.link} 
                    onChange={ (e) => {setSearchAlbum({...searchAlbum, link: e.target.value})} }
                    inputProps={{style: {fontSize: '5vw'}}}
                    InputLabelProps={{style: {fontSize: '5vw'}}}
                  />

                  <Button variant='contained' width='fit-content' fullWidth='false'
                    onClick={() => {
                      spotifySend()
                    }}>
                    <SearchIcon sx={{fontSize: '5vw', paddingRight: '15px'}} />
                    <Typography variant='h4' fontWeight='bold'>search</Typography>
                  </Button>
                  
                </Stack>
              </Card>
            </Grid>
            }

            { page === 1 && options && options.length > 0 && validUser && // SELECT NOMINATION FROM OPTIONS
            <Stack direction='column' alignItems='center' spacing={4} >
              <Stack direction='row' justifyContent='space-around' display='flex' alignItems='center' color='white' width='100%'>

                <IconButton onClick={() => { if(selectedIndex > 0) setSelectedIndex(selectedIndex-1) }}>
                  <KeyboardArrowLeftRoundedIcon fontSize='large' sx={{color: 'white'}} />
                </IconButton>

                { selectedIndex < options.length && // INDEX IS WITHIN BOUNDS
                <Box width='50%'>
                  <Card sx={{paddingX: '15%', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                    <Grid justifyContent='center' alignItems='center' display='flex'>
                      <img src={options[selectedIndex].image.url} width='100%' className='right-shadow-mobile'></img>
                    </Grid>
                    <Box width='100%'>
                      <Typography variant='h5' textAlign='left' fontWeight='bold' paddingTop='20px'>{
                        options[selectedIndex].album.length > 25 ? options[selectedIndex].album.slice(0, 22)+'...' : options[selectedIndex].album
                      }</Typography>
                      <Typography variant='h6' textAlign='left'>{
                        options[selectedIndex].artist.length > 25 ? options[selectedIndex].artist.slice(0, 22)+'...' : options[selectedIndex].artist
                      }</Typography>
                    </Box>
                    <Box textAlign='right' marginRight='-20px'>
                      <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                    </Box>
                  </Card>
                </Box>
                }

                { selectedIndex >= options.length && // WENT PAST OPTIONS (DID NOT FIND)
                <Box width='50%'>
                  <Card sx={{paddingX: '15%', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                    <Grid justifyContent='center' alignItems='center' display='flex'>
                          <img src='images/little-guys/confused.png' width='90%'></img>
                    </Grid>
                    <Box minWidth='100%'>
                      <Typography variant='h5' textAlign='left' fontWeight='bold' paddingTop='20px'>not here?</Typography>
                      <Typography variant='h6' textAlign='left'>select to enter manually</Typography>
                    </Box>
                    <Box textAlign='right' marginRight='-20px'>
                      <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                    </Box>
                  </Card>
                </Box>
                }

                <IconButton onClick={() => { if(selectedIndex < options.length) setSelectedIndex(selectedIndex+1) }}>
                  <KeyboardArrowLeftRoundedIcon fontSize='large' sx={{color: 'white', rotate: '180deg'}} />
                </IconButton>

              </Stack>

              <Stack direction='row' spacing={10}>
                <Button variant='contained'
                  onClick={() => {
                    setPage(0) // go back to first page
                    setSelectedIndex(0) // reset their current selection
                    setOptions() // delete options
                    setMessages({}) // reset error messages
                }}>
                  <Typography variant='h5'>back</Typography>
                </Button>

                <Button variant='contained' 
                  onClick={() => {
                    if(selectedIndex >= options.length) // did not find album
                      setNomination(searchAlbum) // set the album to whatever they entered
                    else {// found album
                      setNomination(options[selectedIndex])
                      getSongs(options[selectedIndex].spotifyID)
                    }
                    setPage(2)
                  }}>
                  <Typography variant='h5'>select</Typography>
                </Button>
              </Stack>
            </Stack>
          }


          { page === 2 && validUser && // FILL IN INFO
            <Card sx={{paddingX: '50px', paddingY: '40px', borderRadius: '20px', backgroundColor: '#fffef2', minHeight: '90%', overflowY: 'auto'}} >
              <Stack direction='column' spacing={3} sx={{width: '100%'}} alignItems='center'>
                {nomination.image && // image found from spotify
                  <Box position='relative' width='90%'>
                    <img src={nomination.image.url} width='100%' className='right-shadow-mobile'></img>
                    { !nomination.spotifyID && // not originally from spotify (edit icon)
                      <IconButton 
                        onClick={() => {setNomination({...nomination, image: undefined})}}
                        sx={{right: 0, bottom: 0, position: 'absolute', background: 'black', ':hover': {background: 'gray'}}}>
                        <EditIcon sx={{color: 'white'}} ></EditIcon>
                      </IconButton>
                    }
                  </Box>
                }
                {!nomination.image && // no image found
                  <TextField fullWidth placeholder='image url'
                  value={nomination.image?.url} 
                  onChange={ (e) => {setNomination({...nomination, image: {url: e.target.value}})} }
                  inputProps={{style: {fontSize: '5vw'}}}
                  InputLabelProps={{style: {fontSize: '5vw'}}}
                />
                }

                <Box width='100%'>
                  <Typography variant='h3' fontWeight='bold'>title: {
                    nomination.album.length > 25 ? nomination.album.slice(0, 22)+'...' : nomination.album
                  }</Typography>
                  <Typography variant='h3' fontWeight='bold'>artist: {
                    nomination.artist.length > 25 ? nomination.artist.slice(0, 22)+'...' : nomination.artist
                  }</Typography>

                  { nomination.spotifyID && // release date found (spotify API)
                    <Typography variant='h6' fontWeight='bold'>released: {nomination.releaseDate}</Typography>
                  }
                  { !nomination.spotifyID && // no release date found (not on spotify)
                    <TextField fullWidth placeholder='release year'
                      value={nomination.releaseDate} 
                      onChange={ (e) => {setNomination({...nomination, releaseDate: e.target.value})} }
                      inputProps={{style: {fontSize: '5vw'}}}
                      InputLabelProps={{style: {fontSize: '5vw'}}}
                      sx={{paddingTop: '3%'}}
                    />
                  }

                  {sampleSongs &&
                  <Box paddingY='5%'>
                    <FormControl fullWidth>
                      <InputLabel>sample song (optional)</InputLabel>
                      <Select 
                        label='sample song (optional)' 
                        value={nomination.sample} 
                        sx={{borderRadius: '50px', maxWidth: '100%'}}
                        onChange={(e) => {setNomination({...nomination, sample: e.target.value})}}
                        
                      >
                          { sampleSongs.map(song => (
                            <MenuItem value={song.id}>{song.name}</MenuItem>
                          )) }
                      </Select>
                    </FormControl>
                    </Box>
                  }
                </Box>
              </Stack>

              <Stack direction='column'>
                <TextField fullWidth placeholder='sub-genres'
                    value={nomination.genre} 
                    onChange={ (e) => {setNomination({...nomination, genre: e.target.value})} }
                    inputProps={{style: {fontSize: '5vw'}}}
                    InputLabelProps={{style: {fontSize: '5vw'}}}
                    sx={{paddingTop: '3%'}}
                />

                <TextField fullWidth placeholder='album description (optional)' multiline
                    value={nomination.description} 
                    onChange={ (e) => {setNomination({...nomination, description: e.target.value})} }
                    inputProps={{style: {fontSize: '5vw'}}}
                    InputLabelProps={{style: {fontSize: '5vw'}}}
                    sx={{paddingTop: '3%', height: '100%'}}
                    rows={7}
                />
                <Typography varaint='p' color={nomination.description?.length > 1000 ? 'red' : 'black'} sx={{paddingLeft: '5px'}}>{`${nomination.description?.length || 0} of max 1000 characters`}</Typography>

                <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '-30px', marginTop: '8%'}}>
                  <Button variant='contained' 
                    onClick={() => { 
                        if(options) setPage(1)
                        else setPage(0)
                        setNomination({}) // reset nomination info
                        setSampleSongs() // reset sample songs
                        setMessages({}) // reset error messages
                    }}>
                      <Typography variant='h5'>back</Typography>
                  </Button>
                  <Button variant='contained' disabled={disabledSubmit} onClick={() => { submitNomination() }}>
                      <Typography variant='h5'>submit</Typography>
                  </Button>
                </Box>
                
              </Stack>
            </Card>
          }


          { page === 3 && validUser && // NOMINATION FINISHED, SHOWS USER THEIR NOMINATION
          <Stack direction='column' justifyContent='center' alignItems='center' paddingBottom='5%'>
            <Typography color='white' variant='h5' fontWeight='medium'>your current nomination:</Typography>
            <Box width='50%'>
                <Card sx={{paddingX: '15%', paddingTop: '30px', paddingBottom: '10px', borderRadius: '20px', backgroundColor: '#fffef2', maxHeight: '600px', margin: '0px'}}>
                  <Grid justifyContent='center' alignItems='center' display='flex'>
                        <img src={nomination.image.url} width='100%' className='right-shadow-mobile'></img>
                  </Grid>
                <Typography variant='h5' textAlign='left' fontWeight='bold' paddingTop='20px'>{
                  nomination.album.length > 25 ? nomination.album.slice(0, 22)+'...' : nomination.album
                }</Typography>
                <Typography variant='h6' textAlign='left'>{
                  nomination.artist.length > 25 ? nomination.artist.slice(0, 22)+'...' : nomination.artist
                }</Typography>
                <Box textAlign='right' marginRight='-20px'>
                  <img src='images/logos/tamu-aotw-logo.png' width='50px' ></img>
                </Box>
              </Card>
            </Box>

            <Stack width='60%' direction='row' justifyContent='space-between' sx={{paddingTop: '20px'}}>
              <Button variant='contained' 
                onClick={() => {
                  setMessages({})
                  setPage(2)
                  getSongs(nomination.spotifyID)
                }}>
                <Typography variant='h5'>edit</Typography>
              </Button>
              <Button variant='contained' onClick={() => deleteNomination()}>
                <Typography variant='h5'>delete</Typography>
              </Button>
            </Stack>
          </Stack>
          }
        </Box>
        <Typography color='#ff6865' variant='h5'>{messages.error}</Typography>
        </Stack>

        <NominationsPage genre={genre} refresh={refresh} />
      </Stack>
    )


  // retreives nomination data (if any)
  function getUserNomination() {
    // send to backend
    const options = {
      method: 'GET',
      url: '/api/user-nomination-info',
      params: {email: user.email}
    }
    axios.request(options).then((res) => {
      let album = res.data.album
      let artist = res.data.artist
      let genre = res.data.genre
      let tracks = res.data.tracks
      let releaseDate = res.data.releaseDate
      let image = res.data.image
      let popularity = res.data.popularity
      let description = res.data.description
      let sample = res.data.sample
      let spotifyID = res.data.spotifyID

      if(album !== undefined) {
        setNomination({...nomination, album: album, artist: artist, genre: genre, tracks: tracks, releaseDate: releaseDate, image: {url: image}, popularity: popularity, spotifyID: spotifyID, description: description, sample: sample})
        setPage(3)
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }

  // gets songs from spotify ID (for loading previous data)
  function getSongs(id) {
    let options = {
      method: 'GET',
      url: '/api/spotify-songs',
      params: {id: id}
    }
    axios.request(options).then((res) => {
      let data = res.data
      if(data.error) {
        console.log(data.message)
        setMessages({error: data.message})
        return
      }
      setSampleSongs(data.sampleSongs)
    }).catch((err) => {
      console.log(err)
    })
  }


  // sends entered data to back end
  // populates page with the response from spotify
  function spotifySend() {
    setMessages({})
    if(((searchAlbum.artist === undefined || searchAlbum.album === undefined || searchAlbum.artist === '' || searchAlbum.album === '') && (searchAlbum.link === undefined || searchAlbum.link === ''))) {
      setMessages({error: 'Please fill in all fields before searching'})
      return
    }

    // send data to backend
    const options = {
      method: 'GET',
      url: '/api/spotify-search',
      params: searchAlbum
    }
    axios.request(options).then((res) => {
      let data = res.data

      // if error
      if(data.error) {
        console.log(data.message)
        setMessages({error: data.message})
        return
      }
      // if album not found, go to final page to fill out data
      if(!data.found) {
        setNomination(searchAlbum)
        setPage(2)
        setMessages({error: data.message})
        return
      }

      // show the albums from the search (if they searched without url)
      if(!data.url) {
        setPage(1)
        setOptions(data.albums)
      }
      // if they searched with url, set the nomination and go to final page
      else { 
        setNomination(data.albumData)
        setPage(2)
        getSongs(data.albumData.spotifyID)
      }
    })
    .catch((err) => {
      console.log(err)
      setMessages({error: 'Error sending data to backend'})
    })
  }


  // send nominations to back end to be stored in database
  function submitNomination() {
    setMessages({})
    if(user === null || user.email === undefined) { // when done testing make sure they sign in with tamu (can take from rating)
      setMessages({error: "Please sign in with your TAMU email."})
      return
    }

    if(nomination.artist === undefined || nomination.album === undefined || nomination.artist === '' || nomination.album === '' || nomination.genre === undefined || nomination.genre === '' || nomination.releaseDate === '' || nomination.releaseDate === undefined){
      setMessages({error: "Please make sure you fill in all blanks"})
      return
    }

    if(!nomination.image && !nomination.image.url) {
      setMessages({error: 'Please provide a link to the album cover before submitting (from rateyourmusic.com or elsewhere).'})
      return
    }

    if(nomination.description?.length > 1000) {
      setMessages({error: 'Please shorten your description.'})
      return
    }

    setDisabledSubmit(true)

    // send data to backend
    const options = {
      method: 'POST',
      url: '/api/insert-nomination',
      data: {email: user.email, album: nomination.album, artist: nomination.artist, genre: nomination.genre, image: nomination.image.url, releaseDate: nomination.releaseDate, tracks: nomination.tracks, popularity: nomination.popularity, description: nomination.description, sample: nomination.sample, spotifyID: nomination.spotifyID}
    }
    axios.request(options).then((res) => {
      if(res.data.error) {
        setMessages({error: res.data.message})
        setDisabledSubmit(false)
        return
      }
      console.log(res.data.message)
      setMessages({success: res.data.message})
      setPage(3)
      setRefresh(!refresh)
      setDisabledSubmit(false)
    })
    .catch((err) => {
      console.log(err)
      setMessages({error: 'There was an error updating your nomination'})
      setDisabledSubmit(false)
    })
  }


  // used to delete the users nomination
  function deleteNomination() {
    setDisabledSubmit(true) // using the same boolean to control delete as submit

    let options = {
      method: 'POST',
      url: '/api/delete-nomination',
      data: {email: user.email}
    }

    axios.request(options).then((res) => {
      if(res.data.error) {
        setMessages({error: 'error deleting nomination'})
      } else {
        // back to page 1 and reset everything
        setNomination({})
        setPage(0)
        setMessages({})
        setSearchAlbum({})
        setSelectedIndex(0)
        setSampleSongs(null)
        setRefresh(!refresh)
      }
      setDisabledSubmit(false)
    })
  }
}