import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Typography, Drawer, Badge, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack, Grid, ButtonBase, Popper, Card, ClickAwayListener } from '@mui/material'
import { Box, maxHeight } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close';

import Loading from '../components/Loading'
import { MobileContext } from '../contexts/MobileContext'

function NominationsPage ({ genre, refresh }) {
    document.title = 'Nominees | AOTW';

    const [nominations, setNominations] = useState('loading') // holds the nominations
    const [selected, select] = useState(null) // holds the selected album
    const [anchorEl, setAnchorEl] = useState(null) // holds the element the user is hovering over
    const [messages, setMessages] = useState({})

    const { mobile } = useContext(MobileContext)

    //small fix

    useEffect(() => {
        // get all the nominations
        let options = {
            method: 'GET',
            url: '/api/all-nominations'
        }
        axios.request(options).then((res) => {
            setNominations(res.data.nominations)
            console.log(res.data.nominations)
        })
        .catch((err) => {
            console.log(err)
            setMessages({error: "There was an error retrieving the nominations"})
        })
    }, [refresh])


    if(nominations === 'loading')
        return <Loading />

    if(!mobile)
        return (
            <Box className='page' margin='25px' marginTop='5%' paddingBottom='10vw'> 
                <Typography variant='h2' fontWeight='bold' marginBottom='2%'>current {(genre + "").toLowerCase()} week nominations:</Typography>
                {nominations && nominations.length > 0 && 
                    <Grid container spacing={6}>
                        {nominations.map((nomination, i) => (
                            <Grid item xs={3}>
                                <img src={nomination.IMAGE} className='right-shadow albumImg' width='100%' 
                                onClick={(e) => {
                                    select(i)
                                    setAnchorEl(e.currentTarget)
                                }}
                                ></img>
                            </Grid>
                        ))}
                    </Grid>
                }
                {(!nominations || nominations.length === 0) &&
                    <Typography variant='h2'>No nominations yet!</Typography>
                }

                { nominations[selected] && 
                    <ClickAwayListener onClickAway={() => select(null)}>
                        <Popper
                            anchorEl={anchorEl}
                            open={anchorEl}
                            placement='top-start'
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            sx={{width: '30%'}}
                            >

                            <Box position='relative' width='100%'>
                                <Card sx={{paddingX: '2.627vw', paddingY: '2.102vw', marginX: '-2.627vw', marginY: '-2.102vw', borderRadius: '20px', backgroundColor: '#fffef2', position: 'absolute', width: '100%', maxHeight: '20vw', overflowY: 'auto'}} >
                                    <Stack direction='row' spacing={3} sx={{width: '100%'}}>
                                        <Box position='relative' width='40%'>
                                            <img src={nominations[selected].IMAGE} width='100%' className='right-shadow'></img>
                                        </Box>
                                        
                                        <Stack direction='column' width='60%'>
                                            <Typography variant='h5' fontWeight='bold'>title: {
                                                nominations[selected].ALBUM.length > 25 ? nominations[selected].ALBUM.slice(0, 22)+'...' : nominations[selected].ALBUM
                                            }</Typography>
                                            <Typography variant='h5' fontWeight='bold'>artist: {
                                                nominations[selected].ARTIST.length > 25 ? nominations[selected].ARTIST.slice(0, 22)+'...' : nominations[selected].ARTIST
                                            }</Typography>
                                            <Typography variant='p' fontWeight='bold'>subgenre: {
                                                nominations[selected].SUBGENRE.length > 25 ? nominations[selected].SUBGENRE.slice(0, 22)+'...' : nominations[selected].SUBGENRE
                                            }</Typography>
                                            <Typography variant='p' fontWeight='bold'>released: {nominations[selected].RELEASE_DATE}</Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack direction='column' width='100%'>
                                        {nominations[selected].SAMPLE && 
                                            <Box className='center' height="90px" marginTop='0.525vw'>
                                                <iframe 
                                                    src={`https://open.spotify.com/embed/track/${nominations[selected].SAMPLE}?utm_source=generator`}  
                                                    height="100" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                                    style={{ top: 0, left: 0, width: '100%', border: 0, margin: 0}}>
                                                </iframe>
                                            </Box>
                                        }
                                        {nominations[selected].DESCRIPTION && 
                                        <>
                                            <Typography variant='p' fontWeight='bold'>album description:</Typography>
                                            <DialogContentText sx={{wordWrap: 'break-word', fontSize: '0.946vw'}}>{nominations[selected].DESCRIPTION}</DialogContentText>
                                        </>
                                        }
                                    </Stack>
                                </Card>
                            </Box>
                        </Popper>
                    </ClickAwayListener>
                }
                
                <Typography sx={{color: 'red'}} align='center' variant='h2' id='display'>{messages.error}</Typography>
                <Typography sx={{color: 'green'}} align='center' variant='h2' id='display'>{messages.success}</Typography>
            </Box>
        )

        if(mobile)
            return (
                <Box className='red-bg' height='1000%' padding='5%' marginBottom='0%' paddingBottom='10%' borderRadius='10px'>
                    <Typography variant='h3' fontStyle='italic' fontWeight='bold' marginBottom='5%' color='white'>current {(genre + "").toLowerCase()} week nominations:</Typography>
                    {nominations && nominations.length > 0 && 
                        <Stack container spacing={4}>
                            {nominations.map((nomination, i) => (
                                <Box className='tan-bg' paddingX='4%' paddingY='2%' borderRadius='10px' onClick={(e) => {
                                    select(i)
                                    setAnchorEl(e.currentTarget)
                                }}>
                                    <Stack direction='row' alignItems='center'>
                                        <img className='right-shadow-mobile' src={nomination.IMAGE} width='25%' height='25%'></img>
                                        <Stack direction='column' marginLeft='5%'>
                                            {/* <Typography><Typography variant='h3' fontWeight='bold'>{nomination.ALBUM}</Typography>({nomination.RELEASE_DATE})</Typography> */}
                                            <Typography variant='h3' fontWeight='bold'>
                                                {nomination.ALBUM.length > 25 ? nomination.ALBUM.slice(0, 22)+'...' : nomination.ALBUM}
                                            </Typography>
                                            <Typography>{nomination.RELEASE_DATE}</Typography>
                                            <Typography>
                                                {nomination.ARTIST.length > 25 ? nomination.ARTIST.slice(0, 22)+'...' : nomination.ARTIST}
                                            </Typography>
                                        </Stack>
                                        
                                    </Stack>
                                </Box>
                            ))}
                        </Stack>
                    }

                    {(!nominations || nominations.length === 0) &&
                        <Typography variant='h2'>No nominations yet!</Typography>
                    }

                    { nominations[selected] && 
                        <ClickAwayListener onClickAway={() => select(null)}>
                            <Popper
                                anchorEl={anchorEl}
                                open={anchorEl}
                                placement='top-start'
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                sx={{width: '90%'}}
                                >

                                <Box position='relative' width='95%'>
                                    <Card sx={{paddingX: '4vw', paddingY: '4vw', marginX: '-2.627vw', marginY: '-2.102vw', borderRadius: '20px', backgroundColor: '#fffef2', position: 'absolute', width: '100%', overflowY: 'auto', maxHeight: '400px'}} >
                                        <Stack direction='row' spacing={3} sx={{width: '100%'}}>
                                            <Box position='relative' width='40%'>
                                                <img src={nominations[selected].IMAGE} width='100%' className='right-shadow'></img>
                                            </Box>
                                            
                                            <Stack direction='column' width='60%'>
                                                <Typography variant='h5' fontWeight='bold'>title: {
                                                    nominations[selected].ALBUM.length > 25 ? nominations[selected].ALBUM.slice(0, 22)+'...' : nominations[selected].ALBUM
                                                }</Typography>
                                                <Typography variant='h5' fontWeight='bold'>artist: {
                                                    nominations[selected].ARTIST.length > 25 ? nominations[selected].ARTIST.slice(0, 22)+'...' : nominations[selected].ARTIST
                                                }</Typography>
                                                <Typography variant='p' fontWeight='bold'>subgenre: {
                                                    nominations[selected].SUBGENRE.length > 25 ? nominations[selected].SUBGENRE.slice(0, 22)+'...' : nominations[selected].SUBGENRE
                                                }</Typography>
                                                <Typography variant='p' fontWeight='bold'>released: {nominations[selected].RELEASE_DATE}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack direction='column' width='100%'>
                                            {nominations[selected].SAMPLE && 
                                                <Box className='center' height="90px" marginTop='0.525vw'>
                                                    <iframe 
                                                        src={`https://open.spotify.com/embed/track/${nominations[selected].SAMPLE}?utm_source=generator`}  
                                                        height="100" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                                        style={{ top: 0, left: 0, width: '100%', border: 0, margin: 0}}>
                                                    </iframe>
                                                </Box>
                                            }
                                            {nominations[selected].DESCRIPTION && 
                                            <>
                                                <Typography variant='p' fontWeight='bold'>album description:</Typography>
                                                <Typography sx={{wordWrap: 'break-word'}}>{nominations[selected].DESCRIPTION}</Typography>
                                            </>
                                            }
                                        </Stack>
                                    </Card>
                                </Box>
                            </Popper>
                        </ClickAwayListener>
                    }
                </Box>
            )
}

export default NominationsPage