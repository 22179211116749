import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import './styles/styles.css'
import { Box } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <html lang="en">
    <meta
      name="TAMU AOTW"
      content="Album of the Week club at Texas A&M"
    />
    <title>TAMU AOTW</title>
    <App />
  </html>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// REMOVED STRICT MODE
// WRAP <App /> WITH
  // <React.StrictMode>
  // </React.StrictMode>

