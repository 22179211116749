// commonly used functions from the front end

// removes the timestamp part that comes with dates when they come from back end
function formatDate(date) {
    let months = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December'
    }
    if(date) {
        let stripped = date.toString().slice(0, 10)
        stripped = stripped.split('-')
        let month = months[stripped[1]]
        return `${month} ${parseInt(stripped[2])}, ${stripped[0]}`
    }
    return ''
}

// makes the timestamp not ugly
function formatTimestamp(timestamp) {
    let months = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December'
    }
    if(!timestamp)
        return ''
    timestamp = timestamp.toString()
    let month = months[timestamp.slice(5, 7)]
    let day = parseInt(timestamp.slice(8, 10)).toString()
    if(day === '1' || day.slice(1) === '1' && day.slice(0, 1) !== '1')
        day += 'st'
    else if(day === '2' || day.slice(1) === '2' && day.slice(0, 1) !== '1')
        day += 'nd'
    else if(day === '3' || day.slice(1) === '3' && day.slice(0, 1) !== '1')
        day += 'rd'
    else
        day += 'th'

    let time = timestamp.slice(11, 16)
    let hour = parseInt(time.slice(0, 2))
    let ampm = hour >= 12 ? 'pm' : 'am'
    if(hour > 12) 
        hour -= 12

    time = hour.toString() + time.slice(2, 5) + ' ' + ampm
    return month + ' ' + day + ' at ' + time
}

// removes the year from a date
function getYear(date) { 
    date = date.toString()
    if(date.length === 4) 
      return date
    return date.slice(0, 4)
  }

export { formatDate, formatTimestamp, getYear }