import { Stack, Box, Typography, Tooltip } from '@mui/material'
import { useState, useContext } from 'react'

import { MobileContext } from '../contexts/MobileContext'

export function Histogram ( { data } )
{
    // data = { label: string, count: int }

    const [ show, toggleShow ] = useState( -1 ) // holds the index of which box to show data for

    const { mobile } = useContext( MobileContext )

    // check data
    if ( !data || data.length < 1 )
        return ( <></> )
    if ( data[ 0 ].label === undefined || data[ 0 ].count === undefined )
        return ( <></> )

    // colors red to green (used for rating)
    const colors = [ '#ff0006', '#f54200', '#e86000', '#d97700', '#c78a00', '#b39a00', '#9ea800', '#86b500', '#6ac000', '#43ca31' ]

    let total = 0
    let maxPercent = 0
    // get total count
    data.forEach( ( e ) =>
    {
        total += e.count
    } )
    // update values with percentage of whole
    for ( let i = 0; i < data.length; i++ )
    {
        let percent = data[ i ].count / total
        data[ i ].percent = percent
        if ( percent > maxPercent )
            maxPercent = percent
    }
    // update values with height relative to others (highest percent is 100% height)
    for ( let i = 0; i < data.length; i++ )
    {
        data[ i ].height = Math.round( ( data[ i ].percent / maxPercent ) * 100 )
        if ( data[ i ].height == 0 ) data[ i ].height = 1
    }

    return (
        <>
            <Stack spacing={ 0 } height='100%' width='100%' flexDirection='row' display='flex' alignItems='flex-end' overflow='hidden' justifyContent="center">
                { data.map( ( bar, index ) => (
                    <Box sx={ { backgroundColor: colors[ index ], height: `${ bar.height }%`, width: `${ 100 / data.length }%`, marginLeft: '2px', marginRight: '2px', position: 'relative', transition: '0.3s ease-in-out', "&:hover": { transform: 'scale(1.1)' } } } onMouseOver={ () => toggleShow( index ) } onClick={ () => toggleShow( index ) }>
                        { show == index && !mobile &&
                            <Typography
                                color='white'
                                fontSize='1.2vw'
                                width='100%'
                                height='100%'
                                sx={ {
                                    opacity: 0,
                                    ":hover": { opacity: 1, transition: 'opacity 0.3s ease-in' },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    zIndex: 1
                                } }
                            >
                                { bar.count }
                            </Typography>
                        }
                    </Box>
                ) ) }
            </Stack>
        </>
    )
}