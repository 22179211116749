import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { MobileContext } from "../contexts/MobileContext";

function NotFoundPage() {
  document.title = "Page Not Found | AOTW";

  const { mobile } = useContext(MobileContext);
  const img_size = mobile ? 200 : 450;

  const quotes = [
    "But I still haven't found what I'm looking for...",
    "Were you ever lost? Was she ever found?",
    "Now you're lost, lost in the heat of it all...",
    "End of my line of sight once more...",
    'And you may ask yourself, "Well, how did I get here?"',
    'I met the devil and he smiled at me and said, "You\'re going the wrong way"',
    "I've been walking, so lost",
    "When you're lost in the rain in Juarez, when it's Easter time, too",
    "I just got lost, every river that I tried to cross, every door I ever tried was locked...",
    "I lost my way, spinning in an endless figure eight",
    "Smell like I sound, I'm lost in a crowd",
    '"He was lost at sea," the paper said',
    "It's been 7 minutes now since I lost my way",
    "There's a high road but I skip the exit when I'm lost, yeah",
    "Save me, save me, save my soul, I couldn't find you there",
    "I'm all lost in the supermarket, I can no longer shop happily",
    "Lost in a galaxy of cocktail bars",
    "Lost in the maze of the back streets I struggled, fighting my way through a whole lot of trouble",
    // Add more quotes here...
  ];
  const quotes_source = [
    "-U2, \"I Still Haven't Found What I'm Looking For\" (1987)",
    '-Beach House, "Space Song" (2015)',
    '-Frank Ocean, "Lost" (2012)',
    '-ODESZA, "Line of Sight" (2017)',
    '-Talking Heads, "Once in a Lifetime" (1980)',
    '-FKA Twigs, "Wrong Way" (2022)',
    '-Arca, "Lost Woman Found" (2021)',
    '-Bob Dylan, "Just Like Tom Thumb\'s Blues" (1965)',
    '-Coldplay, "Lost!" (2008)',
    '-Paramore, "Figure 8" (2023)',
    '-Duran Duran, "Hungry Like the Wolf" (1982)',
    '-Vincent Lima, "Lost at Sea" (2020)',
    '-Dean Lewis, "7 Minutes" (2019)',
    '-NF, "LOST" (2021)',
    '-Nation of Language, "Rush & Fever" (2020)',
    '-The Clash, "Lost in the Supermarket" (1979)',
    '-Lord Huron, "Lost in Time and Space" (2018)',
    '-Tears for Fears, "I Love You But I\'m Lost" (2017)',

    // Add more quotes here...
  ];
  const val = Math.floor(Math.random() * quotes.length);
  const randomQuote = quotes[val];
  const randomQuoteSource = quotes_source[val];

  return (
    <Grid
      container
      alignItems="center"
      style={{ height: "calc(100vh - 158px)" }}
    >
      <Grid item xs={12} sm={6} container justifyContent="center">
        <img
          src="/images/little-guys/confused.png"
          alt="404"
          width={img_size}
          height={img_size}
        />
      </Grid>
      <Grid item xs={12} sm={6} container justifyContent="left">
        <Typography variant="h4">"{randomQuote}"</Typography>
        <Typography variant="p">{randomQuoteSource}</Typography>
        <Box style={{ height: "4em" }} /> {/* Adjust the height as needed */}
        <Typography variant="h6">
          The page you're looking for cannot be found. Sorry about that.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default NotFoundPage;
